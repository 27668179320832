import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link } from 'react-router-dom';
import {
  Button,
  Form,
  Alert,
  Modal,
  Card,
  Nav,
  ListGroup,
  Container,
  Row,
  Col
} from "react-bootstrap";
import Cleave from "cleave.js/react";
import firebase, {
  getRecurrent,
  addRecurrentCharge,
  getRecibosList,
  addRecibo,
  updateRecurrent,
  getClientesList,
  addFile
} from "../../helpers/firebase";
import "./RecurrentChargeBetter.scss";
import "cleave.js/dist/addons/cleave-phone.mx";

import Recibo from "./Recibo/Recibo";
import Layout from "../Layout/Layout";

const Formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  minimumFractionDigits: 2
});

const RecurrentCharge = props => {
  const [doc, setDoc] = useState(null);
  const [formTouched, setFormTouched] = useState(false);
  const [returnedData, setReturnedData] = useState({
    message: "",
    isGood: true,
    isOpen: false
  });
  const [formReciboOpen, setFormReciboOpen] = useState(false);
  const [clickedRecibos, setClickedRecibos] = useState(false);
  const [recibos, setRecibos] = useState(null);
  const [newRecibo, setNewRecibo] = useState({
    persona: "",
    monto: 0,
    moneda: "",
    metodo: "",
    concepto: "",
    creacion: new firebase.firestore.Timestamp.fromDate(new Date()),
    status: false,
    periodo: new firebase.firestore.Timestamp.fromDate(new Date()),
    siguientePeriodo: new firebase.firestore.Timestamp.fromDate(new Date()),
    iva: false
  });
  const [reciboIsOpen, setReciboIsOpen] = useState(false);
  const [reciboData, setReciboData] = useState(null);
  const [cliente, setCliente] = useState({
    empresa: "",
    contacto: "",
    correo: "",
    telefono: "",
    news: false
  });
  const [clientList, setClientList] = useState([]);
  const [stats, setStats] = useState({
    pagado: 0,
    pendiente: 0,
    recibosPendientes: 0
  });

  const logoInput = useRef();
  const logoBackInput = useRef();

  useEffect(() => {
    let listenClientes;
    if(!props.User){
      props.history.push('/login');
    } else {
      if (props.match.params.id) {
        fetchCargo();
        listenClientes = getClientesList().onSnapshot(snap => {
          setClientList(snap.docs);
        });
      }
    }

    return function cleanup() {
      if(listenClientes){
        listenClientes();
      }
    }
  }, [props.User, props.match.params.id]);

  const fetchCargo = () => {
    console.log("corre Cargo");
    getRecurrent(props.match.params.id)
      .then(docume => {
        setDoc({
          ...docume.data(),
          empresa: `/clientes/${docume.data().cliente.id}`
        });
        docume.data().cliente.get().then(client => {
          setCliente(client.data());
        });
        return getRecibosList(docume.data().recibos).then(array => {
          setRecibos(array);
          setNewRecibo({
            ...newRecibo,
            periodo: docume.data().siguiente
          });

          let pagado = 0;
          let recibosPendientes = 0;

          array.forEach(recibo => {
            if(recibo.data().status){
              pagado += parseFloat(recibo.data().monto);
            } else {
              recibosPendientes += parseFloat(recibo.data().monto);
            }
          });

          setStats({
            pagado,
            pendiente: docume.data().monto - pagado,
            recibosPendientes
          })
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleForm = (nombre, valor) => {
    let estado = {
      ...doc
    };

    if (nombre === "siguiente") {
      estado[nombre] = new firebase.firestore.Timestamp.fromDate(
        new Date(valor)
      );
    } else if (nombre === "monto") {
      estado[nombre] = parseFloat(valor.substr(1));
    } else if (nombre === "empresa") {
      firebase.firestore().doc(valor).get().then(resp => {
        setCliente(resp.data());
      });
      estado[nombre] = valor;
    } else {
      estado[nombre] = valor;
    }

    if (!formTouched) {
      setFormTouched(true);
    }

    setDoc(estado);
  };

  const handleSubmit = ev => {
    ev.preventDefault();

    let newDoc = {
      ...doc,
      monto: parseFloat(doc.monto),
      cliente: firebase.firestore().doc(doc.empresa)
    };

    addRecurrentCharge(newDoc, props.match.params.id)
      .then(doc => {
        setReturnedData({
          isGood: true,
          isOpen: true,
          message: "Los datos se han guardado con exito!"
        });
        setTimeout(() => {
          setReturnedData({
            ...returnedData,
            isOpen: false
          });
        }, 2000);
      })
      .catch(err => {
        setReturnedData({
          isOpen: true,
          isGood: false,
          message: err
        });
        setTimeout(() => {
          setReturnedData({
            ...returnedData,
            isOpen: false
          });
        }, 2000);
      });
  };

  const handleFormRecibo = (nombre, valor) => {
    let estado = {
      ...newRecibo
    };

    if (nombre === "periodo") {
      const fecha = new Date(valor);
      let periodo = 1;
      switch (doc.frecuencia) {
        case "trimestre":
          periodo = 3;
          break;
        case "semestre":
          periodo = 6;
          break;
        case "ano":
          periodo = 12;
          break;
        case "unico":
          periodo = 12;
          break;
        default:
          break;
      }
      estado[nombre] = new firebase.firestore.Timestamp.fromDate(fecha);
      let nextDate = fecha.setMonth(fecha.getMonth() + periodo);
      estado.siguientePeriodo = new firebase.firestore.Timestamp.fromMillis(
        nextDate
      );
    } else {
      estado[nombre] = valor;
    }

    setNewRecibo(estado);
  };

  const handleCancelRecurrente = () => {
    updateRecurrent(props.match.params.id, {
      active: false
    })
      .then(returned => {
        fetchCargo();
        setReturnedData({
          isGood: false,
          isOpen: true,
          message: "Cargo Recurrente Cancelado"
        });
        setTimeout(() => {
          setReturnedData({
            ...returnedData,
            isOpen: false
          });
        }, 3000);
      })
      .catch(erro => {
        setReturnedData({
          isOpen: true,
          isGood: false,
          message: erro
        });
        setTimeout(() => {
          setReturnedData({
            ...returnedData,
            isOpen: false
          });
        }, 3000);
      });
  };

  const handleTerminarRecurrente = () => {
    updateRecurrent(props.match.params.id, {
      finished: true
    })
      .then(returned => {
        fetchCargo();
        setReturnedData({
          isGood: false,
          isOpen: true,
          message: "Cargo Recurrente Terminado"
        });
        setTimeout(() => {
          setReturnedData({
            ...returnedData,
            isOpen: false
          });
        }, 3000);
      })
      .catch(erro => {
        setReturnedData({
          isOpen: true,
          isGood: false,
          message: erro
        });
        setTimeout(() => {
          setReturnedData({
            ...returnedData,
            isOpen: false
          });
        }, 3000);
      });
  };

  const newReciboOpen = () => {
    let periodo = 1;
    switch (doc.frecuencia) {
      case "trimestre":
        periodo = 3;
        break;
      case "semestre":
        periodo = 6;
        break;
      case "ano":
        periodo = 12;
        break;
      case "unico":
        periodo = 12;
        break;
      default:
        break;
    }
    let nextDate = newRecibo.periodo
      .toDate()
      .setMonth(newRecibo.periodo.toDate().getMonth() + periodo);
    setNewRecibo({
      ...newRecibo,
      monto: doc.monto,
      moneda: doc.moneda ? doc.moneda : 'MXN',
      siguientePeriodo: new firebase.firestore.Timestamp.fromMillis(nextDate)
    });
    setFormReciboOpen(true);
  };

  const newReciboClose = () => {
    let periodo = 1;
    switch (doc.frecuencia) {
      case "trimestre":
        periodo = 3;
        break;
      case "semestre":
        periodo = 6;
        break;
      case "ano":
        periodo = 12;
        break;
      case "unico":
        periodo = 12;
        break;
      default:
        break;
    }
    let nextDate = doc.siguiente
      .toDate()
      .setMonth(doc.siguiente.toDate().getMonth() + periodo);
    setNewRecibo({
      ...newRecibo,
      persona: "",
      monto: 0,
      moneda: "",
      metodo: "",
      concepto: "",
      creacion: new firebase.firestore.Timestamp.fromDate(new Date()),
      status: false,
      periodo: doc.siguiente,
      siguientePeriodo: new firebase.firestore.Timestamp.fromMillis(nextDate),
      iva: false
    });
    setFormReciboOpen(false);
  };

  const handleFormReciboSubmit = () => {
    const saveRecibo = {...newRecibo, monto: parseFloat(newRecibo.monto)}
    addRecibo(saveRecibo)
      .then(referencia => {
        let newDoc = {
          ...doc,
          recibos: doc.recibos ? [...doc.recibos, referencia] : [referencia],
          siguiente: newRecibo.siguientePeriodo
        };

        setDoc(newDoc);
        addRecurrentCharge(newDoc, props.match.params.id)
          .then(doc => {
            setReturnedData({
              isGood: true,
              isOpen: true,
              message: "Los datos se han guardado con exito!"
            });
            fetchCargo();
            setTimeout(() => {
              setReturnedData({
                ...returnedData,
                isOpen: false
              });
              newReciboClose();
            }, 2000);
          })
          .catch(err => {
            console.log(err);
            setReturnedData({
              isOpen: true,
              isGood: false,
              message: "error greve"
            });
            setTimeout(() => {
              setReturnedData({
                ...returnedData,
                isOpen: false
              });
            }, 2000);
          });
      })
      .catch(err => {
        console.log(err);
        setReturnedData({
          isOpen: true,
          isGood: false,
          message: "error greve"
        });
        setTimeout(() => {
          setReturnedData({
            ...returnedData,
            isOpen: false
          });
        }, 2000);
      });
  };

  const openReciboData = elemento => {
    setReciboData(elemento);
    setReciboIsOpen(true);
  };

  const handleLogo = () => {
    logoInput.current.click();
  }

  const handleBack = () => {
    logoBackInput.current.click();
  }

  const submitLogo = () => {
    const file = logoInput.current.files[0] ? logoInput.current.files[0] : undefined;

    if(!file) {
      return;
    }

    const uploadTask = addFile(cliente.empresa, file.name, file);

    uploadTask.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
        default:
          break;
      }
    }, error => {
      // Handle unsuccessful uploads

      console.log(error);
    }, () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at', downloadURL);

        updateRecurrent(props.match.params.id, {
          logoURL: downloadURL
        })
          .then(doc => {
            fetchCargo();
            setReturnedData({
              isGood: true,
              isOpen: true,
              message: "Los datos se han guardado con exito!"
            });
            setTimeout(() => {
              setReturnedData({
                ...returnedData,
                isOpen: false
              });
            }, 2000);
          })
          .catch(err => {
            setReturnedData({
              isOpen: true,
              isGood: false,
              message: err
            });
            setTimeout(() => {
              setReturnedData({
                ...returnedData,
                isOpen: false
              });
            }, 2000);
          });
      });
    });
  }

  const submitBack = () => {
    const file = logoBackInput.current.files[0] ? logoBackInput.current.files[0] : undefined;

    if(!file) {
      return;
    }

    const uploadTask = addFile(cliente.empresa, file.name, file);

    uploadTask.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
        default:
          break;
      }
    }, error => {
      // Handle unsuccessful uploads

      console.log(error);
    }, () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at', downloadURL);

        updateRecurrent(props.match.params.id, {
          logoBackURL: downloadURL
        })
          .then(doc => {
            fetchCargo();
            setReturnedData({
              isGood: true,
              isOpen: true,
              message: "Los datos se han guardado con exito!"
            });
            setTimeout(() => {
              setReturnedData({
                ...returnedData,
                isOpen: false
              });
            }, 2000);
          })
          .catch(err => {
            setReturnedData({
              isOpen: true,
              isGood: false,
              message: err
            });
            setTimeout(() => {
              setReturnedData({
                ...returnedData,
                isOpen: false
              });
            }, 2000);
          });
      });
    });
  }

  return (
    <Layout>
      <Container>
        <Row>
          <Col className="my-3">
            {doc && <Link to="/"><h5>&lt;- Regresar</h5></Link>}
            {doc && <h1>Datos Operación {doc.internalId}</h1>}
            {doc && doc.url && <a href={doc.url} target="_blank" rel="noopener noreferrer"><p style={{margin:0}}>Visitar Página Web</p></a>}
            {doc && !doc.active && <Alert variant="danger" className="my-3">NOTA - Este cargo se ha cancelado</Alert>}
            {doc && doc.finished && <Alert variant="success" className="my-3">Este Proyecto ya está Cerrado</Alert>}
          </Col>
        </Row>
        <Row>
          <Col className="my-3">
            {doc ? (
              <Card>
                <Card.Header>
                  <Nav fill variant="tabs" defaultActiveKey="#datos">
                    <Nav.Item>
                      <Nav.Link
                        href="#datos"
                        onClick={() => setClickedRecibos(false)}
                      >
                        Datos
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        href="#recibos"
                        onClick={() => setClickedRecibos(true)}
                      >
                        Recibos
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {!clickedRecibos ? (
                    <div>
                      {doc.active && !doc.finished ? (
                        <>
                          <Button
                            variant="danger"
                            onClick={handleCancelRecurrente}
                            className="my-3"
                          >
                            Cancelar Cargo
                          </Button>
                          <Button
                            variant="success"
                            onClick={handleTerminarRecurrente}
                            className="m-3"
                          >
                            Terminar Proyecto
                          </Button>
                          <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="concepto">
                              <Form.Label>Proyecto</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Marketing Digital"
                                value={doc.concepto}
                                onChange={e =>
                                  handleForm("concepto", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group controlId="empresa">
                              <Form.Label>Empresa</Form.Label>
                              <Form.Control as="select" value={doc.empresa} onChange={(e) => handleForm("empresa", e.target.value)}>
                                <option value="" disabled>-- Selecciona una opción --</option>
                                {clientList.map(cliente => {
                                  return (
                                    <option key={cliente.id} value={`/clientes/${cliente.id}`}>{cliente.data().empresa}</option>
                                  )
                                })}
                              </Form.Control>
                            </Form.Group>
                            <p><Link to={`/cliente/${doc.cliente.id}`}>Editar Datos de Empresa</Link></p>
                            <p>Persona de Contacto: {cliente.contacto}</p>
                            <p>Correo: {cliente.correo}</p>
                            <p>Teléfono: {cliente.telefono}</p>
                            <Form.Group controlId="frecuencia">
                              <Form.Label>Frecuencia</Form.Label>
                              <Form.Control
                                as="select"
                                value={doc.frecuencia}
                                onChange={e =>
                                  handleForm("frecuencia", e.target.value)
                                }
                              >
                                <option value="mes">Mensual</option>
                                <option value="trimestre">Trimestral</option>
                                <option value="semestre">Semestral</option>
                                <option value="ano">Anual</option>
                                <option value="unico">Único</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Row>
                              <Col md={6}>
                                <Form.Group controlId="tipo">
                                  <Form.Label>Tipo de Proyecto</Form.Label>
                                  <Form.Control
                                    as="select"
                                    value={doc.tipo ? doc.tipo : ""}
                                    onChange={e =>
                                      handleForm("tipo", e.target.value)
                                    }
                                  >
                                    <option value="" disabled>-- Elige una Opción --</option>
                                    <option value="tienda">Tienda en Linea</option>
                                    <option value="pagina">Página Web</option>
                                    <option value="plataforma">Plataforma Web</option>
                                    <option value="marketing">Marketing Digital</option>
                                    <option value="crm">CRM</option>
                                    <option value="otro">Otro</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group controlId="url">
                                  <Form.Label>URL de Proyecto</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="http://lycklig.com.mx"
                                    value={doc.url ? doc.url : ""}
                                    onChange={e =>
                                      handleForm("url", e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Form.Row>
                            
                            <Form.Row>
                              <Col md={6}>
                                <Form.Group controlId="monto">
                                  <Form.Label>Monto Total del Proyecto</Form.Label>
                                  <Cleave
                                    placeholder="$3,000"
                                    className="form-control"
                                    options={{
                                      numeral: true,
                                      prefix: "$ ",
                                      numeralThousandsGroupStyle: "thousand"
                                    }}
                                    value={doc.monto}
                                    onChange={e =>
                                      handleForm("monto", e.target.rawValue)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group controlId="moneda">
                                  <Form.Label>Moneda</Form.Label>
                                  <Form.Control
                                    as="select"
                                    value={doc.moneda ? doc.moneda : 'MXN'}
                                    onChange={e =>
                                      handleForm("moneda", e.target.value)
                                    }
                                  >
                                    <option value="" disabled>-- Elige una Opción --</option>
                                    <option value="MXN">MXN</option>
                                    <option value="USD">USD</option>
                                    <option value="EUR">EUR</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            </Form.Row>
                            <p>
                              Fecha de Inicio:{" "}
                              {doc.inicio.toDate().toLocaleString()}
                            </p>
                            <Form.Group controlId="siguiente">
                              <Form.Label>Fecha de Siguiente Recibo</Form.Label>
                              <Form.Control
                                type="date"
                                value={doc.siguiente
                                  .toDate()
                                  .toISOString()
                                  .substr(0, 10)}
                                onChange={e =>
                                  handleForm("siguiente", e.target.value)
                                }
                              />
                            </Form.Group>
                            {returnedData.isOpen ? (
                              <Alert
                                variant={
                                  returnedData.isGood ? "success" : "danger"
                                }
                                className="my-3"
                              >
                                {returnedData.message}
                              </Alert>
                            ) : null}
                            {formTouched && (
                              <Button variant="dark" type="submit">
                                Actualizar Datos
                              </Button>
                            )}
                          </Form>
                        </>
                      ) : (
                        <p>
                          Empresa: {cliente.empresa}
                          <br />
                          Proyecto: {doc.concepto}
                          <br />
                          Frecuencia: {doc.frecuencia}
                          <br />
                          Monto: {Formatter.format(doc.monto)}
                          <br />
                          Moneda: {doc.moneda ? doc.moneda : 'MXN'}
                          <br />
                          Inicio: {doc.inicio.toDate().toLocaleString()}
                          <br />
                          {doc.tipo === "pagina" || doc.tipo === "tienda" || doc.tipo === "plataforma" ? (
                            <>Página: {doc.url && <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.url}</a>}<br/></>
                          ) : null}
                          Último Recibo:{" "}
                          {doc.siguiente.toDate().toLocaleString()}
                          <br />
                        </p>
                      )}
                      <div className="proyect-images">
                        {doc.logoURL && <img src={doc.logoURL} alt={`Logo ${cliente.empresa}`} />}
                        {doc.logoBackURL && <img src={doc.logoBackURL} alt={`Background ${cliente.empresa}`} />}
                      </div>
                      <Button className="my-3" variant="dark" onClick={handleLogo}>Subir Logo</Button>
                      <Button className="m-3" variant="dark" onClick={handleBack}>Subir Background</Button>
                      <input ref={logoInput} type="file" onChange={submitLogo} accept="image/png, image/jpeg" style={{display: "none"}}/>
                      <input ref={logoBackInput} type="file" onChange={submitBack} accept="image/png, image/jpeg" style={{display: "none"}}/>
                    </div>
                  ) : (
                    <div>
                      <h4 style={{
                        color: 'green'
                      }}>Pagado: {Formatter.format(stats.pagado)} {doc.moneda ? doc.moneda : 'MXN'}</h4>
                      {doc && doc.frecuencia === 'unico' && (
                        <>
                          <h4 style={{
                            color: 'red'
                          }}>Restante del Proyecto: {Formatter.format(stats.pendiente)} {doc.moneda ? doc.moneda : 'MXN'}</h4>
                          <h4 style={{
                            color: 'red'
                          }}>Recibos Pendientes de Pago: {Formatter.format(stats.recibosPendientes)} {doc.moneda ? doc.moneda : 'MXN'}</h4>
                        </>
                      )}
                      {doc.active && !doc.finished ? (
                        <Card.Body>
                          <Button
                            variant="success"
                            block
                            className="my-3"
                            onClick={newReciboOpen}
                          >
                            Nuevo Recibo
                          </Button>
                        </Card.Body>
                      ) : (
                        <Alert variant="danger" className="my-3">
                          NOTA - Este cargo se ha cancelado o cerrado y no se pueden generar más recíbos
                        </Alert>
                      )}
                      <Card>
                        <ListGroup variant="flush">
                          {recibos
                            ? recibos.map(elemento => {
                                return (
                                  <ListGroup.Item
                                    key={elemento.id}
                                    variant={
                                      elemento.data().status
                                        ? "success"
                                        : "danger"
                                    }
                                    className="Recibo__Elemento-Lista"
                                    onClick={() => openReciboData(elemento)}
                                  >
                                    {elemento.data().status
                                      ? "Pagado"
                                      : "Pendiente"}{" "}
                                    |{" "}
                                    {elemento
                                      .data()
                                      .periodo.toDate()
                                      .toLocaleDateString()}{" "}
                                    -{" "}
                                    {elemento
                                      .data()
                                      .siguientePeriodo.toDate()
                                      .toLocaleDateString()}
                                  </ListGroup.Item>
                                );
                              })
                            : null}
                        </ListGroup>
                      </Card>
                      <Modal
                        size="lg"
                        show={formReciboOpen}
                        onHide={newReciboClose}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Agregar Recibo de Pago</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group controlId="persona">
                              <Form.Label>Persona que Paga</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Rafael González"
                                value={newRecibo.persona}
                                onChange={e =>
                                  handleFormRecibo("persona", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group controlId="concepto">
                              <Form.Label>Concepto de Recibo</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Facebook Ads Mensual"
                                value={newRecibo.concepto}
                                onChange={e =>
                                  handleFormRecibo("concepto", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group controlId="montoapagar">
                              <Form.Label>Monto a Pagar ({doc.moneda})</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="3000"
                                prefix={doc.moneda}
                                value={newRecibo.monto}
                                onChange={e =>
                                  handleFormRecibo("monto", e.target.value)
                                }
                              />
                            </Form.Group>
                            <Form.Group controlId="ivacheck">
                              <Form.Check type="checkbox" label="Cobrar IVA" checked={newRecibo.iva} onChange={e =>
                                handleFormRecibo("iva", e.target.checked)
                              }/>
                            </Form.Group>
                            <Form.Group controlId="metodo">
                              <Form.Label>Método de Pago</Form.Label>
                              <Form.Control
                                as="select"
                                defaultValue={newRecibo.metodo}
                                onChange={e =>
                                  handleFormRecibo("metodo", e.target.value)
                                }
                              >
                                <option value="" disabled>
                                  -- Selecciona una opción --
                                </option>
                                <option value="tpv">
                                  Tarjeta de Crédito / Debito
                                </option>
                                <option value="efectivo">Efectivo</option>
                                <option value="transferencia">
                                  Transferencia
                                </option>
                                <option value="deposito">Depósito</option>
                                <option value="btc">Bitcoins</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="periodo">
                              <Form.Label>Periodo de Recibo del</Form.Label>
                              <Form.Control
                                type="date"
                                value={newRecibo.periodo
                                  .toDate()
                                  .toISOString()
                                  .substr(0, 10)}
                                onChange={e =>
                                  handleFormRecibo("periodo", e.target.value)
                                }
                              />
                              <Form.Text className="text-muted">
                                al{" "}
                                {newRecibo.siguientePeriodo
                                  .toDate()
                                  .toLocaleDateString()}
                              </Form.Text>
                            </Form.Group>
                            <p>
                              Se generará un recibo de pago con estatus
                              PENDIENTE DE PAGO, se contará con 5 días habiles a
                              partir de la fecha de inicio de periodo (
                              {newRecibo.periodo
                                .toDate()
                                .toLocaleDateString("es-MX")}
                              ) para liquidar sin incurrir en penalizaciones.
                            </p>
                          </Form>
                          {returnedData.isOpen ? (
                            <Alert
                              variant={returnedData.isGood ? "dark" : "danger"}
                              className="my-3"
                            >
                              {returnedData.message}
                            </Alert>
                          ) : null}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="dark"
                            onClick={handleFormReciboSubmit}
                          >
                            Guardar
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  )}
                </Card.Body>
                {reciboData && (
                  <Recibo
                    IsOpen={reciboIsOpen}
                    CloseFunc={() => setReciboIsOpen(false)}
                    ReciboData={reciboData}
                    Formatter={Formatter}
                    RefetchFunc={fetchCargo}
                    CargoId={props.match.params.id}
                  />
                )}
              </Card>
            ) : (
              <Card.Body>
                <h4>Loading...</h4>
              </Card.Body>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default withRouter(RecurrentCharge);
