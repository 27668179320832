import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Form, Modal, Alert } from 'react-bootstrap';
import { getClientesList, addCliente, updateMember } from '../../helpers/firebase';

import Layout from '../Layout/Layout';
import ClientList from '../ClientesList/ClientesList';

const Clientes = (props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [clientList, setClientList] = useState([]);
    const [addClient, setAddClient] = useState(false);
    const [newCliente, setNewCliente] = useState({
        empresa: "",
        contacto: "",
        correo: "",
        telefono: "",
        news: true
    });
    const [alertNewCliente, setAlertNewCliente] = useState({
        open: false,
        error: false,
        message: ""
    });
    const [filteredClientes, setFilteredClientes] = useState([[], false]);

    useEffect(() => {
        let observadorClientes;

        if(!props.User){
            props.history.push('/login');
        } else {
            observadorClientes = getClientesList().onSnapshot(snap => {
                setClientList(snap.docs);
            });
        }

        return function clearAll() {
            observadorClientes();
        }
    }, [props.User]);

    const handleSearchChange = e => {
        const valor = e.target.value;
        setSearchTerm(valor);

        let newArray = [];

        if(valor.length > 3){
            newArray = clientList.filter(element => {
                const patt = new RegExp(`${valor}`, "i");
          
                const empresa = element.data().empresa;
                const check1 = empresa.match(patt);
          
                const contacto = element.data().contacto;
                const check2 = contacto.match(patt);

                const correo = element.data().correo;
                const check3 = correo.match(patt);
                
                return (check1 || check2 || check3);
            });

            setFilteredClientes([newArray, true]);
        } else {
            setFilteredClientes([[], false]);
        }
    }

    const handleNewClienteChange = (nombre, valor) => {
        let newState = {
            ...newCliente
        }

        newState[nombre] = valor;

        setNewCliente(newState);
    }

    const handelNewClienteClose = () => {
        setNewCliente({
            empresa: "",
            contacto: "",
            correo: "",
            telefono: "",
            news: true
        });

        setAddClient(false);
    }

    const handleGuardarCliente = () => {
        if(newCliente.contacto === "" || newCliente.correo === "" || newCliente.telefono === "") {
            setAlertNewCliente({
                error: true,
                open: true,
                message: "Por favor llena todos los campos requeridos"
            });
            setTimeout(() => {
                setAlertNewCliente({
                    error: false,
                    open: false,
                    message: ""
                });
            }, 3000);
        } else  {
            addCliente(newCliente)
                .then(data => {
                    return updateMember(newCliente);
                })
                .then(respuesta => {
                    setAlertNewCliente({
                        error: false,
                        open: true,
                        message: "Los datos se guardaron correctamente"
                    });
                    setTimeout(() => {
                        setAlertNewCliente({
                            error: false,
                            open: false,
                            message: ""
                        });
                        handelNewClienteClose();
                    }, 1500);
                })
                .catch(error => {
                    setAlertNewCliente({
                        error: true,
                        open: true,
                        message: "Error: " + JSON.stringify(error)
                    });
                    setTimeout(() => {
                        setAlertNewCliente({
                            error: false,
                            open: false,
                            message: ""
                        });
                    }, 3000);
                })
        }
    }

    return (
        <Layout>
            <Container>
                <Row>
                    <Col className="my-3">
                        <h1>Clientes de Acromático</h1>
                        <Button variant="dark" onClick={() => setAddClient(true)}>Nuevo Cliente</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                            <Card.Title>Buscar</Card.Title>
                            <Form.Control type="text" placeholder="Rafael González Vázquez" value={searchTerm} className="my-3" onChange={handleSearchChange} />
                            <Card.Title>Lista de Cargos</Card.Title>
                                {clientList.length === 0 ? (
                                    <Card.Text>Loading...</Card.Text>
                                ) : (
                                    <ClientList Lista={filteredClientes[1] ? filteredClientes[0] : clientList}/>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" show={addClient} onHide={handelNewClienteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="empresa">
                            <Form.Label>Empresa</Form.Label>
                            <Form.Control type="text" placeholder="Acromático Development" value={newCliente.empresa} onChange={(e) => handleNewClienteChange("empresa", e.target.value)} />
                        </Form.Group>
                        <Form.Group controlId="empresa">
                            <Form.Label>Nombre de Contacto *</Form.Label>
                            <Form.Control type="text" placeholder="Rafael González" value={newCliente.contacto} onChange={(e) => handleNewClienteChange("contacto", e.target.value)} />
                        </Form.Group>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group controlId="correo">
                                    <Form.Label>Correo *</Form.Label>
                                    <Form.Control type="email" placeholder="hola@acromatico.co" value={newCliente.correo} onChange={(e) => handleNewClienteChange("correo", e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="telefono">
                                    <Form.Label>Teléfono *</Form.Label>
                                    <Form.Control type="text" placeholder="+525512952572" value={newCliente.telefono} onChange={(e) => handleNewClienteChange("telefono", e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Check 
                            type="checkbox"
                            id={`default-checkbox`}
                            label="Suscrito a Noticias"
                            checked={newCliente.news}
                            onChange={(e) => handleNewClienteChange("news", e.target.checked)}
                        />
                    </Form>
                    {alertNewCliente.open && <Alert variant={alertNewCliente.error ? "danger" : "success"} className="my-3">{alertNewCliente.message}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleGuardarCliente}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>   
    )
}

export default withRouter(Clientes);