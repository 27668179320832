import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import './RecurrentChargeList.scss';

const RecurrentChargeList = (props) => {
  const chargeList = props.Lista;

  return (
    <div>
      <Table responsive className="Tabla__Container" style={{
        maxHeight: "50px",
      }}>
        <thead>
          <tr>
            <th>Folio</th>
            <th>Empresa</th>
            <th>Concepto</th>
            <th>Frecuencia</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          {chargeList.map((elemento) => {
            let colorStyle;

            if(!elemento.data().active){
              colorStyle = {
                color: "white",
                backgroundColor: "#dc3545"
              }
            } else if(elemento.data().finished) {
              colorStyle = {
                color: "white",
                backgroundColor: "green"
              }
            }

            let Cliente = props.Clientes.find(cli => {
              const clienteId = elemento.data().cliente ? elemento.data().cliente.id : "Sin Id";
              return cli.id === clienteId;
            });

            return (
              <tr key={elemento.id} style={colorStyle}>
                <td>{elemento.data().internalId}<br/><Link to={`/operacion/${elemento.id}`}><Button variant="dark">Ver</Button></Link></td>
                <td>{Cliente ? Cliente.data().empresa : "no hay"}</td>
                <td>{elemento.data().concepto}</td>
                <td>{elemento.data().frecuencia}</td>
                <td>{props.Formatter.format(elemento.data().monto) + " " + (elemento.data().moneda ? elemento.data().moneda : 'MXN')}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
};

export default RecurrentChargeList;
