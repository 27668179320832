import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Button, ButtonGroup, Form, Alert } from 'react-bootstrap';
import firebase, { getRecurrentList, addRecurrentCharge, getRecibosVencidos, getClientesList } from './helpers/firebase';
import { withRouter } from 'react-router-dom';
import './App.scss';
import 'cleave.js/dist/addons/cleave-phone.mx';

import RecurrentChargeList from './containers/RecurrentChargeList/RecurrentChargeList';
import Layout from './containers/Layout/Layout';
import Vencidos from './containers/Vencidos/Vencidos';

const App = (props) => {
  const [cargos, setCargos] = useState([]);
  const [filteredCargos, setFilteredCargos] = useState([[], false]);
  const [showModal, setShowModal] = useState(false);
  const [returnedData, setReturnedData] = useState({
    message: "",
    isGood: true,
    isOpen: false
  });
  const [newRecurr, setNewRecurr] = useState({
    active: true,
    empresa: "",
    concepto: "",
    frecuencia: "",
    cliente: null,
    monto: 0,
    tipo: "",
    url: "",
    inicio: new firebase.firestore.Timestamp.now(),
    internalId: null,
    siguiente: new firebase.firestore.Timestamp.now()
  });
  const [vencidosArray, setVencidosArray] = useState([]);
  const [totalVencido, setTotalVencido] = useState(0);
  const [filterActivos, setFilterActivos] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [clientList, setClientList] = useState([]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    let listenRecurr;
    let listenVencido;
    let listenClientes;
    if(!props.User){
      props.history.push('/login');
    } else {
      if(props.User !== 'iniciando'){
        window.ga('send', 'pageview');
        console.log('corre Lista');
        listenRecurr = getRecurrentList().onSnapshot((snap) => {
          let newCargos = [];

          snap.forEach(async doc => {
            let empresa = await doc.data().cliente.get();
            doc.empresa = empresa.data();

            newCargos.push(doc);
          });

          setCargos(newCargos);
          handleActivos(snap.docs);
        });
        
        listenVencido = getRecibosVencidos().onSnapshot((snapshot) => {
          let da = snapshot.docs;
          let totalVen = 0;
          snapshot.forEach((doc) => {
            totalVen += parseInt(doc.data().monto);
          });
          setTotalVencido(totalVen);
          setVencidosArray(da);
        });

        listenClientes = getClientesList().onSnapshot(snap => {
          setClientList(snap.docs);
        });
      }
    }

    return function cleanup() {
      if(listenRecurr){
        listenRecurr();
      }

      if(listenVencido){
        listenVencido();
      }

      if(listenClientes) {
        listenClientes();
      }
    };
  }, [
    props.User
  ]);

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
  });

  const handleSearchChange = (e) => {
    let newSearch = e.target.value;
    handelFilter(newSearch, filterActivos);
    setSearchTerm(newSearch);
  }

  const handelFilter = (text, activeFilter, arrayCargos=cargos) => {
    const query = text;
    let newArray = [];

    if (query.length > 3){
      newArray = arrayCargos.filter(element => {
        const patt = new RegExp(`${query}`, "i");
  
        const empresa = element.empresa.empresa;
        const check1 = empresa.match(patt);

        const contacto = element.empresa.contacto;
        const check2 = contacto.match(patt);
  
        const folio = element.data().internalId;
        const check3 = folio.match(patt);

        let filterActive = true;

        if(activeFilter){
          filterActive = element.data().active && !element.data().finished;
        }
        
        return ((check1 || check2 || check3) && filterActive);
      });

      setFilteredCargos([newArray, true]);
    } else if (activeFilter) {
      newArray = arrayCargos.filter(element => {
        let filterActive = true;
        
        filterActive = element.data().active && !element.data().finished;
        
        return filterActive;
      })
      setFilteredCargos([newArray, true]);
    } else {
      setFilteredCargos([[], false]);
    }
  };

  const handleForm = (nombre, valor) => {
    let estado = {
      ...newRecurr
    }

    if(nombre === "siguiente"){
      estado[nombre] = new firebase.firestore.Timestamp.fromDate(new Date(valor));
    } else if (nombre === "monto") {
      if(valor === ""){
        estado[nombre] = 0;
      } else {
        estado[nombre] = parseFloat(valor);
      }
      
    } else {
      estado[nombre] = valor;
    }

    setNewRecurr(estado);
  }

  const handleGuardar = () => {
    let newDoc = {
      ...newRecurr,
      internalId: `AC-${cargos.length + 1}`,
      monto: parseFloat(newRecurr.monto),
      cliente: firebase.firestore().doc(newRecurr.empresa),
      empresa: null
    }

    if(!newRecurr.empresa || !newRecurr.concepto || !newRecurr.frecuencia || newRecurr.monto === 0 || !newRecurr.monto ){
      setReturnedData({
        isOpen: true,
        isGood: false,
        message: "Por favor completa todos los datos!"
      })
      setTimeout(()=>{
        setReturnedData({
          ...returnedData,
          isOpen: false,
        })
      }, 2000);
    } else {
      addRecurrentCharge(newDoc)
        .then(doc => {
          setReturnedData({
            isOpen: true,
            isGood: true,
            message: "Los datos se han guardado con exito!"
          })
          setTimeout(()=>{
            setReturnedData({
              ...returnedData,
              isOpen: false,
            });

            setNewRecurr({
              active: true,
              empresa: "",
              concepto: "",
              frecuencia: "",
              cliente: null,
              monto: 0,
              inicio: new firebase.firestore.Timestamp.now(),
              internalId: null,
              siguiente: new firebase.firestore.Timestamp.now()
            });

            setShowModal(false);
          }, 2000);
        })
        .catch(err => {
          setReturnedData({
            isOpen: true,
            isGood: false,
            message: err
          })
          setTimeout(()=>{
            setReturnedData({
              ...returnedData,
              isOpen: false,
            })
          }, 2000);
        })
    }
  };

  const handleActivos = (arrayCargos=cargos) => {
    let filtro = !filterActivos;
    setFilterActivos(filtro);
    handelFilter(searchTerm, filtro, arrayCargos);
  }

  return (
    <Layout>
      {props.User === 'iniciando' ? (
        <Container>
          <Row>
            <Col className="my-3">
              <h1>Cargando Datos...</h1>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col className="my-3">
              <h1>Seguimiento a Operaciones</h1>
              <Button variant="dark" onClick={()=>setShowModal(true)}>Agregar Cargo</Button>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="my-3">
              <Card>
                <Card.Body>
                  <Card.Title>Recibos Vencidos</Card.Title>
                  <Card.Subtitle className="my-3">
                    Monto Total Vencido: {formatter.format(totalVencido)}
                  </Card.Subtitle>
                  <div style={{
                    width: "100%",
                    overflowX: "scroll",
                    overflowY: "hidden",
                    height: "150px",
                    border: "1px solid grey",
                    display: "block"
                  }}>
                    <Vencidos ArrayVencidos={vencidosArray} Formatter={formatter}/>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="my-3">
              <Card>
                <Card.Body>
                  <Card.Title>Buscar</Card.Title>
                  <Form.Control type="text" placeholder="AC-1 ó Acromático Development" value={searchTerm} className="my-3" onChange={handleSearchChange} />
                  <ButtonGroup className="my-3" aria-label="Filtros">
                    <Button variant={filterActivos ? "primary" : "outline-primary"} onClick={()=>handleActivos(cargos)}>Activos</Button>
                  </ButtonGroup>
                  <Card.Title>Lista de Cargos</Card.Title>
                  <RecurrentChargeList Lista={filteredCargos[1] ? filteredCargos[0] : cargos} Formatter={formatter} Clientes={clientList}/>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      <Modal size="lg" show={showModal} onHide={()=>setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Cargo Recurrente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="empresa">
              <Form.Label>Empresa</Form.Label>
              <Form.Control type="text" value={newRecurr.empresa} onChange={(e) => handleForm("empresa", e.target.value)} disabled />
            </Form.Group>
            <Form.Group controlId="empresa">
              <Form.Label>Empresa</Form.Label>
              <Form.Control as="select" value={newRecurr.empresa} onChange={(e) => handleForm("empresa", e.target.value)}>
                <option value="" disabled>-- Selecciona una opción --</option>
                {clientList.map(cliente => {
                  return (
                    <option key={cliente.id} value={`/clientes/${cliente.id}`}>{cliente.data().empresa}</option>
                  )
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="concepto">
              <Form.Label>Concepto</Form.Label>
              <Form.Control type="text" placeholder="Marketing Digital" value={newRecurr.concepto} onChange={(e) => handleForm("concepto", e.target.value)} />
            </Form.Group>
            <Form.Row>
              <Col md={6}>
                <Form.Group controlId="frecuencia">
                  <Form.Label>Frecuencia</Form.Label>
                  <Form.Control as="select" value={newRecurr.frecuencia} onChange={(e) => handleForm("frecuencia", e.target.value)}>
                    <option value="" disabled>-- Selecciona una opción --</option>
                    <option value="mes">Mensual</option>
                    <option value="trimestre">Trimestral</option>
                    <option value="semestre">Semestral</option>
                    <option value="ano">Anual</option>
                    <option value="unico">Único</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="monto">
                  <Form.Label>Monto</Form.Label>
                  <Form.Control type="number" placeholder="3000" value={newRecurr.monto.toString()} onChange={(e) => handleForm("monto", e.target.value)} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="tipo">
                  <Form.Label>Tipo de Proyecto</Form.Label>
                  <Form.Control
                    as="select"
                    value={newRecurr.tipo ? newRecurr.tipo : ""}
                    onChange={e =>
                      handleForm("tipo", e.target.value)
                    }
                  >
                    <option value="" disabled>-- Elige una Opción --</option>
                    <option value="tienda">Tienda en Linea</option>
                    <option value="pagina">Página Web</option>
                    <option value="plataforma">Plataforma Web</option>
                    <option value="marketing">Marketing Digital</option>
                    <option value="crm">CRM</option>
                    <option value="otro">Otro</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="url">
                  <Form.Label>URL de Proyecto</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="http://lycklig.com.mx"
                    value={newRecurr.url ? newRecurr.url : ""}
                    onChange={e =>
                      handleForm("url", e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={6}>
                <p>Fecha de Inicio: {newRecurr.inicio.toDate().toLocaleDateString()}</p>
              </Col>
              <Col md={6}>
                <Form.Group controlId="siguiente">
                  <Form.Label>Fecha de Siguiente Recibo</Form.Label>
                  <Form.Control type="date" value={newRecurr.siguiente.toDate().toISOString().substr(0, 10)} onChange={(e) => handleForm("siguiente", e.target.value)} />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
          {returnedData.isOpen ? (
            <Alert variant={returnedData.isGood ? "dark" : "danger"} className="my-3">
              {returnedData.message}
            </Alert>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleGuardar}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default withRouter(App);
