const sendMail = (mesageTo, subject, body) => {
  const datosCorreo = {
    address: mesageTo,
    subject: subject,
    body: body
  };

  return fetch('https://local-delivery-app.acromati.co/mail-sender/send', {
    method: 'POST', // or 'PUT'
    body: JSON.stringify(datosCorreo), // data can be `string` or {object}!
    headers:{
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
};

export {
  sendMail
};