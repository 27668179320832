import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyAsNMBF6RSYoZnS5RnPdg9DEyxNKxCXKEo",
  authDomain: "cargos-recurrentes-acromatico.firebaseapp.com",
  databaseURL: "https://cargos-recurrentes-acromatico.firebaseio.com",
  projectId: "cargos-recurrentes-acromatico",
  storageBucket: "cargos-recurrentes-acromatico.appspot.com",
  messagingSenderId: "999898293587",
  appId: "1:999898293587:web:2082fc48ebef874b0b6da6"
};

firebase.initializeApp(config);

//Auth Config
export const login = (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const logout = () => {
  return firebase.auth().signOut();
};

//FirestoreConfig
const db = firebase.firestore();

export const getRecurrentList = (empresa="") => {
  let referencia = db.collection("recurrente");
  if (empresa !== ""){
    referencia = referencia.where("empresa", "==", empresa)
  }
  return referencia;
};

export const getRecurrent = (id) => {
  return db.collection("recurrente").doc(id).get();
};

export const getRecurrentByRecibo = (idRecibo) => {
  const reciboRef = db.collection("invoice").doc(idRecibo);
  return db.collection("recurrente").where("recibos", "array-contains", reciboRef).get();
};

export const getRecurrentByClient = (idCliente) => {
  const clienteRef = db.collection("clientes").doc(idCliente);
  return db.collection("recurrente").where("cliente", "==", clienteRef);
};

export const addRecurrentCharge = (data, id=null) => {
  
  if(id){
    return db.collection("recurrente").doc(id).update(data);
  } else {
    return db.collection("recurrente").doc().set(data);
  }
  
};

export const updateRecurrent = (id, newData) => {
  if(id){
    return db.collection("recurrente").doc(id).update(newData);
  } else {
    return false;
  }
};

export const getRecibosList = (listsRef) => {
  if(listsRef){
    const promiseArray = listsRef.map(elemnto => {
      return new Promise((res,rej) => {
        res(elemnto.get());
      });
    });
    return Promise.all(promiseArray);
  } else {
    return Promise.resolve([]);
  }
};

export const getRecibosVencidos = () => {
  return db.collection("invoice").where("status", "==", false).orderBy("periodo");
};

export const getRecibosPagados = () => {
  return db.collection("invoice").where("status", "==", true).orderBy("periodo");
};

export const getRecibo = (id) => {
  return db.collection("invoice").doc(id).get();
};

export const addRecibo = (data, id=null) => {
  if(id){
    return db.collection("invoice").doc(id).set(data);
  } else {
    return db.collection("invoice").add(data);
  }
};

export const updateRecibo = (id, newData) => {
  if(id){
    return db.collection("invoice").doc(id).update(newData);
  } else {
    return false;
  }
};

export const deleteRecibo = (refRecibo, idRecurrente) => {
  return db.collection("recurrente").doc(idRecurrente).update({
    recibos: firebase.firestore.FieldValue.arrayRemove(refRecibo)
  }).then(() => {
    return refRecibo.delete();
  });
};

export const getClientesList = () => {
  let referencia = db.collection("clientes");
  
  return referencia;
}

export const getCliente = (id) => {
  let referencia = db.collection("clientes").doc(id);

  return referencia;
}

export const addCliente = (data, id=null) => {
  
  if(id){
    return db.collection("clientes").doc(id).update(data);
  } else {
    return db.collection("clientes").doc().set(data);
  }
  
};

//Firebase Functions
export const sendSMS = (to, body) => {
  const sendSMS = firebase.functions().httpsCallable('sendSMS');
  return sendSMS({
    to,
    message: body
  });
}

export const updateMember = (cliente) => {
  const updateMember = firebase.functions().httpsCallable('createMember');
  return updateMember({
    email: cliente.correo,
    name: cliente.contacto,
    subscribed: cliente.news,
    vars: cliente
  });
}

//Firebase Storage
export const addFile = (cliente, nombre, file) => {
  const storageRef = firebase.storage().ref();

  const metadata = {
    contentType: file.type
  };

  const uploadTask = storageRef.child(`${cliente}/${nombre}`).put(file, metadata);

  return uploadTask;
}

export default firebase;
