import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Alert, Modal, Col } from 'react-bootstrap';
import firebase, { addRecibo, updateRecibo, getRecurrentByRecibo, deleteRecibo, sendSMS } from '../../../helpers/firebase';
import { sendMail } from '../../../helpers/mailSender';
import './Recibo.scss';

const Recibo = (props) => {
  const id = props.ReciboData.id
  const [data, setData] = useState(props.ReciboData.data());
  const [alerta, setAlerta] = useState({
    isOpen: false,
    message: "",
    isGood: false
  });
  const [recurrente, setRecurrente] = useState({
    active: false,
    concepto: "",
    empresa: "",
    frecuencia: "",
    inicio: null,
    internalId: "",
    monto: 0,
    recibos: [],
    siguiente: null
  });

  const [cliente, setCliente] = useState({
    empresa: "",
    contacto: "",
    correo: "",
    telefono: "",
    news: false
  });

  useEffect(() => {
    console.log('Corrio Efecto', props.CargoId);
    setData(props.ReciboData.data());
    getRecurrentByRecibo(id)
      .then(recurrente => {
        if(!recurrente.empty){
          setRecurrente(recurrente.docs[0].data());
          recurrente.docs[0].data().cliente.get().then(client => {
            setCliente(client.data());
          });
        } else {
          return Promise.reject("not found");
        }
      })
      .catch(erro => {
        setAlerta({
          isOpen: true,
          isGood: false,
          message: `Error: ${erro}`
        });
        setTimeout(() => {
          setAlerta({
            isOpen: false,
            isGood: false,
            message: ""
          });
        }, 5000);
      });
  },[props.ReciboData]);

  const handleActualizar = () => {
    let newState = {
      ...data
    };

    newState.fechaPago = new firebase.firestore.Timestamp.fromDate(new Date());
    newState.status = true;
    newState.monto = parseFloat(data.monto);

    addRecibo(newState, id)
      .then(ref => {
        setData(newState);
        props.RefetchFunc();
      })
      .catch(err=> {
        console.log(err);
      });
  };

  const handleForm = (name, value) => {
    let newSatet = {
      ...data
    };

    newSatet[name] = value;

    setData(newSatet);
  };

  const handleSolicitar = () => {
    sendMail(
      cliente.correo,
      "Recibo Pendiente de Pago Acromático",
      `
        <h1>Recibo pendiente de pago a nombre de ${cliente.empresa}</h1>
        <p>
          Hola ${cliente.empresa}, tenemos un recibo pendiente de pago con fecha limite ${new Date(data.periodo.toDate().setDate(data.periodo.toDate().getDate() + 5)).toLocaleDateString()}.<br/>
          Recibo Pendiente de Pago:
        </p>
        <p>
          Proyecto: ${recurrente.concepto}<br/>
          Concepto: ${data.concepto ? data.concepto : 'Sin Concepto'}<br/>
          ${recurrente.frecuencia !== 'unico' ? `Periodo: Del ${data.periodo.toDate().toLocaleDateString()} al ${data.siguientePeriodo.toDate().toLocaleDateString()}<br/>` : ''}
          Monto de Recibo: ${props.Formatter.format(data.monto)}<br/>
        </p>
        <p>
          <a href="https://recurrentes.acromatico.co/recibo/${id}">Pagar Recibo Pendiente</a>
        </p>
      `
    )
    .then(response => {
      setAlerta({
        isOpen: true,
        isGood: true,
        message: `El correo se ha enviado exitosamente a ${cliente.correo}`
      });
      setTimeout(() => {
        setAlerta({
          isOpen: false,
          isGood: true,
          message: ""
        });
      }, 5000);
    })
    .catch(error => {
      setAlerta({
        isOpen: true,
        isGood: false,
        message: `Error: ${error}`
      });
      setTimeout(() => {
        setAlerta({
          isOpen: false,
          isGood: false,
          message: ""
        });
      }, 5000);
    });
  };

  const handleSendRecibo = () => {
    sendMail(
      cliente.correo,
      "Copia de Recibo Acromático",
      `
        <h1>
          Para consultar tu recibo haz click <a href="https://recurrentes.acromatico.co/recibo/${id}">aquí</a>
        </h1>
      `
    )
    .then(response => {
      setAlerta({
        isOpen: true,
        isGood: true,
        message: `El correo se ha enviado exitosamente a ${cliente.correo}`
      });
      setTimeout(() => {
        setAlerta({
          isOpen: false,
          isGood: true,
          message: ""
        });
      }, 5000);
    })
    .catch(error => {
      setAlerta({
        isOpen: true,
        isGood: false,
        message: `Error: ${error}`
      });
      setTimeout(() => {
        setAlerta({
          isOpen: false,
          isGood: false,
          message: ""
        });
      }, 5000);
    });
  };

  const handleUpdateRecibo = () => {
    updateRecibo(id, data)
      .then(respuesta => {
        setAlerta({
          isOpen: true,
          isGood: true,
          message: `Datos Actualizados`
        });
        setTimeout(() => {
          setAlerta({
            isOpen: false,
            isGood: true,
            message: ""
          });
          props.RefetchFunc();
        }, 5000);
      })
      .catch(err => {
        setAlerta({
          isOpen: true,
          isGood: false,
          message: `Error: ${err}`
        });
        setTimeout(() => {
          setAlerta({
            isOpen: false,
            isGood: false,
            message: ""
          });
        }, 5000);
      })
  }

  const handleEliminarRecibo = () => {
    deleteRecibo(props.ReciboData.ref, props.CargoId)
      .then(() => {
        console.log("Borrado");
        props.CloseFunc();
        props.RefetchFunc();
      })
  }

  const handleSMSRecibo = () => {
    sendSMS(cliente.telefono, `Consulta tu recibo de pago en la siguiente liega: https://recurrentes.acromatico.co/recibo/${id}`)
      .then(resp => {
        //const mensaje = resp.data;
        setAlerta({
          isOpen: true,
          isGood: true,
          message: `El SMS se ha enviado exitosamente a ${cliente.telefono}`
        });
        setTimeout(() => {
          setAlerta({
            isOpen: false,
            isGood: true,
            message: ""
          });
        }, 5000);
      })
      .catch(error => {
        setAlerta({
          isOpen: true,
          isGood: false,
          message: `Error: ${error}`
        });
        setTimeout(() => {
          setAlerta({
            isOpen: false,
            isGood: false,
            message: ""
          });
        }, 5000);
      })
  }

  const handleSMSSolicitar = () => {
    sendSMS(cliente.telefono, `Paga tu recibo de Acromático en la siguiente liga: https://recurrentes.acromatico.co/recibo/${id}`)
      .then(resp => {
        //const mensaje = resp.data;
        setAlerta({
          isOpen: true,
          isGood: true,
          message: `El SMS se ha enviado exitosamente a ${cliente.telefono}`
        });
        setTimeout(() => {
          setAlerta({
            isOpen: false,
            isGood: true,
            message: ""
          });
        }, 5000);
      })
      .catch(error => {
        setAlerta({
          isOpen: true,
          isGood: false,
          message: `Error: ${error}`
        });
        setTimeout(() => {
          setAlerta({
            isOpen: false,
            isGood: false,
            message: ""
          });
        }, 5000);
      });
  }

  return (
    <Modal size="lg" show={props.IsOpen} onHide={props.CloseFunc}>
      <Modal.Header closeButton>
        <Modal.Title>Actualizar Recibo de Pago</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.status ? <Alert variant="success">Este recibo se ha pagado con fecha {data.fechaPago.toDate().toLocaleString()}</Alert> : null}
        <h3>{recurrente.internalId} | {cliente.empresa}</h3>
        <h4>Datos de transacción</h4>
        <p>
          Fecha de Creación: {data.creacion.toDate().toLocaleString()}<br/>
          Periodo de Recibo: {data.periodo.toDate().toLocaleDateString()} al {data.siguientePeriodo.toDate().toLocaleDateString()}<br/>
          Monto de Recibo: {data.iva ? props.Formatter.format(data.monto * 1.16) + " IVA incluido" : props.Formatter.format(data.monto)}<br/>
          Moneda: {data.moneda ? data.moneda : 'MXN'}
        </p>
        {!data.status ? (
          <div>
            <h4>Pagar Recibo</h4>
            <Form>
              <Form.Row>
                <Form.Group as={Col} sm={12} controlId="persona">
                  <Form.Label>Persona que Paga</Form.Label>
                  <Form.Control type="text" placeholder="Rafael González" value={data.persona} onChange={(e) => handleForm("persona", e.target.value)} />
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="concepto">
                  <Form.Label>Concepto</Form.Label>
                  <Form.Control type="text" placeholder="Facebook Ads Mensual" value={data.concepto ? data.concepto : ""} onChange={(e) => handleForm("concepto", e.target.value)} />
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="metodo">
                  <Form.Label>Método de Pago</Form.Label>
                  <Form.Control as="select" value={data.metodo} onChange={(e) => handleForm("metodo", e.target.value)}>
                    <option value="tpv">Tarjeta de Crédito / Debito</option>
                    <option value="efectivo">Efectivo</option>
                    <option value="transferencia">Transferencia</option>
                    <option value="deposito">Depósito</option>
                    <option value="btc">Bitcoins</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="ivaCheck">
                  <Form.Check type="checkbox" label="Cobrar IVA" checked={data.iva} onChange={e =>
                      handleForm("iva", e.target.checked)
                  }/>
                </Form.Group>
              </Form.Row>
            </Form>
          </div>
        ) : (
          <div>
            <h4>Datos del Pago</h4>
            <p>
              Pagado por: {data.persona}<br/>
              Concepto Pagado: {data.concepto ? data.concepto : "Sin Concepto"}<br/>
              Método de Pago: {data.metodo}<br/>
              Fecha de Pago: {data.fechaPago ? data.fechaPago.toDate().toLocaleString() : "Hubo un Erro!"}
            </p>
          </div>
        )}
        {alerta.isOpen ? (
          <Alert variant={alerta.isGood ? "success" : "danger"}>
            {alerta.message}
          </Alert>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Link to={`/recibo/${id}`} target="_blank">Ver Recibo</Link>
        {!data.status ? (
          <div>
            <Button variant="dark" className="m-1" onClick={handleUpdateRecibo}>Actualizar</Button>
            <Button variant="danger" className="m-1" onClick={handleSolicitar}>Solicitar Pago por Correo</Button>
            <Button variant="danger" className="m-1" onClick={handleSMSSolicitar}>Solicitar Pago por Teléfono</Button>
            <Button variant="danger" className="m-1" onClick={handleEliminarRecibo}>Eliminar</Button>
            <Button variant="success" className="m-1" onClick={handleActualizar}>Pagar</Button>
          </div>
        ) : (
          <div>
            <Button variant="success" className="m-1" onClick={handleSendRecibo}>Enviar Recibo por Correo</Button>
            <Button variant="success" className="m-1" onClick={handleSMSRecibo}>Enviar Recibo por Teléfono</Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
};

export default Recibo;
