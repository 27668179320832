import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Form, ListGroup, Alert } from 'react-bootstrap';
import Cleave from "cleave.js/react";
import { getCliente, addCliente, getRecurrentByClient, updateMember } from '../../helpers/firebase';

import Layout from '../Layout/Layout';

import "cleave.js/dist/addons/cleave-phone.mx";

const Formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
});

const Cliente = (props) => {
    const [cliente, setCliente] = useState(null);
    const [formTouched, setFormTouched] = useState(false);
    const [returnedData, setReturnedData] = useState({
        message: "",
        isGood: true,
        isOpen: false
    });
    const [operaciones, setOperaciones] = useState([]);
    
    useEffect(() => {
        let observadorCliente;
        let observerRecurr;

        if(!props.User){
            props.history.push('/login');
        } else {
            observadorCliente = getCliente(props.match.params.id).onSnapshot(snap => {
                setCliente(snap.data());
            });
            observerRecurr = getRecurrentByClient(props.match.params.id).onSnapshot(snap => {
                setOperaciones(snap.docs);
            });
        }

        return function clearAll() {
            observadorCliente();
            observerRecurr();
        }
    }, [
        props.User,
        props.match.params.id
    ]);

    const handleClientFormChange = (nombre, valor) => {
        let estado = {
            ...cliente
        };
    
        estado[nombre] = valor;
        
        if (!formTouched) {
            setFormTouched(true);
        }
    
        setCliente(estado);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        let newDoc = {
            ...cliente
        };
  
        addCliente(newDoc, props.match.params.id)
            .then(doc => {
                return updateMember(newDoc);
            })
            .then(datos => {
                setReturnedData({
                    isGood: true,
                    isOpen: true,
                    message: "Los datos se han guardado con exito!"
                });
                setTimeout(() => {
                    setReturnedData({
                        ...returnedData,
                        isOpen: false
                    });
                }, 2000);
            })
            .catch(err => {
                setReturnedData({
                    isOpen: true,
                    isGood: false,
                    message: err
                });
                setTimeout(() => {
                    setReturnedData({
                        ...returnedData,
                        isOpen: false
                    });
                }, 2000);
            });
    }

    return (
        <Layout>
            <Container>
                {!cliente ? (
                    <Row>
                        <Col className="my-3">
                            <h1>Loading...</h1>
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col className="my-3">
                                <Link to="/clientes"><h5>&lt;- Regresar</h5></Link>
                                <h1>{cliente.empresa}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7} className="my-3">
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Datos de Cliente</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <p>Datos Requeridos *</p>
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="empresa">
                                                <Form.Label>Empresa *</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Acromático Development"
                                                    value={cliente.empresa}
                                                    onChange={e =>
                                                        handleClientFormChange("empresa", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="contacto">
                                                <Form.Label>Persona de Contacto *</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Rafael González"
                                                    value={cliente.contacto}
                                                    onChange={e =>
                                                        handleClientFormChange("contacto", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="correo">
                                                <Form.Label>Correo *</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="hola@acromatico.co"
                                                    value={cliente.correo}
                                                    onChange={e =>
                                                        handleClientFormChange("correo", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="telefono">
                                                <Form.Label>Celular *</Form.Label>
                                                <Cleave
                                                    placeholder="+52 1 55 1234 5678"
                                                    className="form-control"
                                                    options={{
                                                        phone: true,
                                                        phoneRegionCode: 'MX',
                                                        prefix: '+52'
                                                    }}
                                                    value={cliente.telefono}
                                                    onChange={e =>
                                                        handleClientFormChange("telefono", e.target.rawValue)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Check 
                                                type="checkbox"
                                                id={`default-checkbox`}
                                                label="Suscrito a Noticias"
                                                checked={cliente.news}
                                                onChange={(e) => handleClientFormChange("news", e.target.checked)}
                                            />
                                            {returnedData.isOpen && (
                                                <Alert
                                                    variant={
                                                    returnedData.isGood ? "success" : "danger"
                                                    }
                                                    className="my-3"
                                                >
                                                    {returnedData.message}
                                                </Alert>
                                            )}
                                            {formTouched && <Button variant="dark" type="submit" className="my-3">Guardar Cambios</Button>}
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={5} className="my-3">
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Operaciones</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {operaciones.length > 0 ? (
                                            <ListGroup variant="flush">
                                                {operaciones.map(oper => {
                                                    return (
                                                        <ListGroup.Item
                                                            key={oper.id}
                                                            variant={oper.data().active ? "success" : "danger"}
                                                            className="Recibo__Elemento-Lista" >
                                                                <Link to={`/operacion/${oper.id}`}>
                                                                    <h4>{oper.data().internalId}</h4>
                                                                    <p>
                                                                        {oper.data().concepto}<br/>
                                                                        {Formatter.format(oper.data().monto)}
                                                                    </p>
                                                                </Link>
                                                        </ListGroup.Item>
                                                    )
                                                })}
                                            </ListGroup>
                                        ) : (
                                            <Card.Text>Sin Operaciones</Card.Text>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )}
                
            </Container>
        </Layout>
    )
}

export default withRouter(Cliente);