import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import './index.css';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<BrowserRouter><StripeProvider apiKey="pk_live_2Xw91Oyu6xpqB4tHBHxxtQNy00lnGtsdg3"><Elements><Routes /></Elements></StripeProvider></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
