import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import './Vencidos.scss';

import Recibo from '../RecurrentChargeBetter/Recibo/Recibo';

const Vencidos = (props) => {
  const [arrayVencidos, setArrayVencidos] = useState(props.ArrayVencidos);
  const [reciboData, setReciboData] = useState(null);
  const [reciboIsOpen, setReciboIsOpen] = useState(false);

  useEffect(() => {
    setArrayVencidos(props.ArrayVencidos);
  }, [props]);

  const openReciboData = (elemento) => {
    setReciboData(elemento);
    setReciboIsOpen(true);
  };

  return (
    <div style={{
      width: "fit-content",
      height: "100%",
      display: "flex",
      alignItems: "center"
    }}>
      {arrayVencidos.map(ele => {
        return (
          <Card key={ele.id} bg="danger" text="white" className="mx-3 my-auto click-card" style={{
            width: "200px",
            height: "75%",
            display: "inline-block",
            position: "unset"
          }} onClick={()=>openReciboData(ele)}>
            <Card.Body>
              Fecha: {ele.data().periodo.toDate().toLocaleDateString()}<br/>
              Monto: {props.Formatter.format(ele.data().monto)}
            </Card.Body>
          </Card>
        )
      })}
      {reciboData ? <Recibo IsOpen={reciboIsOpen} CloseFunc={()=>setReciboIsOpen(false)} ReciboData={reciboData} Formatter={props.Formatter} RefetchFunc={()=>console.log('nothing to fetch')} /> : null}
    </div>
  );
};

export default Vencidos;