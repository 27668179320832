import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
// import Cleave from 'cleave.js/react';
import { Container, Row, Col, Card, Modal, Button, Form, Alert } from 'react-bootstrap';
import firebase, { getRecibo, getRecurrentByRecibo, updateRecibo, sendSMS } from '../../helpers/firebase';
import { sendMail } from '../../helpers/mailSender';
import { CardElement, injectStripe } from 'react-stripe-elements';

// import generateToken from '../../helpers/conekta';

import './ReciboClientView.scss';

const ReciboClientView = (props) => {
  const [recibo, setRecibo] = useState({
    creacion: null,
    fechaPago: null,
    metodo: "",
    monto: 0,
    moneda: "",
    periodo: null,
    persona: "",
    siguientePeriodo: null,
    status: false,
    iva: false
  });
  const [recurrente, setRecurrente] = useState({
    active: false,
    concepto: "",
    frecuencia: "",
    inicio: null,
    internalId: "",
    monto: 0,
    recibos: [],
    siguiente: null
  });
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [waitingConekta, setConekta] = useState(false);
  const [paymentIsOpen, setPaymentIsOpen] = useState(false);
  // const [cardData, setCardData] = useState({
  //   "number": "",
  //   "name": "",
  //   "exp_year": "",
  //   "exp_month": "1",
  //   "cvc": ""
  // });
  const [alerta, setAlerta] = useState({
    isOpen: false,
    type: "danger",
    message: ""
  });
  const [cliente, setCliente] = useState({
    empresa: "",
    contacto: "",
    correo: "",
    telefono: "",
    news: false
  });

  useEffect(() => {
    console.log('consume base');
    
    reFetchData()
      .finally(()=> {
        setLoading(true);
      });
    
  }, [
    props.match.params.id
  ]);

  const reFetchData = () => {
    setLoading(false);
    return getRecibo(props.match.params.id)
      .then(rec => {
        if(rec.exists){
          setRecibo(rec.data());
          return getRecurrentByRecibo(rec.id);
        } else {
          return Promise.reject("not found");
        }
      })
      .then(recurrente => {
        if(!recurrente.empty){
          setRecurrente(recurrente.docs[0].data());
          recurrente.docs[0].data().cliente.get().then(clie => {
            setCliente(clie.data());
          });
          setFound(true);
        } else {
          return Promise.reject("not found");
        }
      })
      .catch(err => {
        setFound(false);
        setRecibo({
          creacion: null,
          fechaPago: null,
          metodo: "",
          monto: 0,
          periodo: null,
          persona: "",
          siguientePeriodo: null,
          status: false
        });
        console.log(err);
      });
  };

  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
  });

  const handlePagar = () => {
    setPaymentIsOpen(true);
  };

  const closePayment = () => {
    setPaymentIsOpen(false);
  };

  const handleForm = (name, value) => {
    let newState = {
      ...recibo
    };

    newState[name] = value;

    setRecibo(newState);
  };

  // const handlePago = (e) => {
  //   e.preventDefault();

  //   console.log("Monto: ", recurrente.monto * 100);

  //   if(!window.Conekta.card.validateNumber(cardData.number) || !window.Conekta.card.validateExpirationDate(cardData.exp_month, cardData.exp_year) || !window.Conekta.card.validateCVC(cardData.cvc)){
  //     setAlerta({
  //       isOpen: true,
  //       type: "danger",
  //       message: "Los datos enviados no son validos, favor de revisarlos"
  //     });
  //     setTimeout(()=> {
  //       setAlerta({
  //         isOpen: false,
  //         type: "danger",
  //         message: ""
  //       });
  //     }, 3000);
  //     return;
  //   }

  //   generateToken(cardData)
  //     .then(token => {
  //       const tokenId = token.id;

  //       const datos = {
  //         cardTokenId: tokenId,
  //         item: {
  //           id: props.match.params.id,
  //           name: `${cliente.empresa} | Cargo Recurrente`,
  //           description: `${recurrente.concepto}`,
  //           unit_price: recurrente.monto * 100,
  //           quantity: 1,
  //           tags: ["Cargo Recurrente"],
  //           brand: "Acromático Development"
  //         },
  //         customer: {
  //           name: cardData.name,
  //           email: cliente.correo,
  //           phone: cliente.telefono
  //         },
  //         meta: {}
  //       }

  //       setConekta(true);

  //       return fetch('https://local-delivery-app.acromati.co/payments/pay', {
  //         method: 'POST', // or 'PUT'
  //         body: JSON.stringify(datos), // data can be `string` or {object}!
  //         headers:{
  //           'Content-Type': 'application/json'
  //         }
  //       })
  //       .then(res => res.json())
  //       .then(response => {

  //         if(!response.payment_status || response.payment_status !== "paid"){
  //           return Promise.reject({
  //             status: response.http_code,
  //             message: response.details[0].message
  //           });
  //         } else {
  //           return updateRecibo(props.match.params.id, {
  //             status: true,
  //             idCargoConekta: response.charges.data[0].id,
  //             metodo: "tpv",
  //             persona: recibo.persona,
  //             fechaPago: new firebase.firestore.Timestamp.fromDate(new Date())
  //           });
  //         }
  //       })
  //       .then(() => {
  //         reFetchData();
  //         setConekta(false);
  //         setAlerta({
  //           isOpen: true,
  //           type: "success",
  //           message: "Tu pago se ha recibido de manera exitosa, gracias :)"
  //         });
  //         setTimeout(()=> {
  //           setAlerta({
  //             isOpen: false,
  //             type: "danger",
  //             message: ""
  //           });
  //         }, 3000);
  //         sendMail(
  //           cliente.correo,
  //           "Recibo Acromático Development",
  //           `
  //             <h1>¡${recibo.persona} Gracias por tu Preferencia!</h1>
  //             <p>
  //               Tu pago con tarjeta ha sido procesado con exito,
  //               puedes consultar una copia de dicho recibo <a href="https://recurrentes.acromatico.co/recibo/${props.match.params.id}">aquí</a>.
  //             </p>
  //           `
  //         )
  //       })
  //       .catch(erro => {
  //         return Promise.reject(erro);
  //       });
  //     })
  //     .catch(error => {
  //       setConekta(false);
  //       setAlerta({
  //         isOpen: true,
  //         type: "danger",
  //         message: error.message
  //       });
  //     })
  //     .finally(()=> {
  //       setLoading(true);
  //     });
  // }

  const stipeSubmit = async (ev) => {
    try {
      setConekta(true);
      let { token } = await props.stripe.createToken({name: `${recibo.persona}`});
      const montoAPagar = recibo.iva ? Math.floor(recibo.monto * 100 * 1.035 * 1.16 ) : Math.floor(recibo.monto * 100 * 1.035);

      const datos = {
        amount: montoAPagar, 
        currency: recibo.moneda ? recibo.moneda.toLowerCase() : 'mxn',
        description: `${recurrente.concepto}`,
        source: token.id,
        metadata: {
          recurrentes_id: props.match.params.id
        },
        receipt_email: cliente.correo
      }

      console.log(datos);

      let response = await fetch("https://local-delivery-app.acromati.co/stripe/charge", {
        method: "POST",
        body: JSON.stringify(datos),
        headers:{
          'Content-Type': 'application/json'
        }
      });

      let finalResponse = await response.json();

      console.log(finalResponse);

      if(finalResponse.statusCode){
        throw new Error(finalResponse.raw.message);
      } else if(finalResponse.status === "succeeded"){
        updateRecibo(props.match.params.id, {
          status: true,
          cargoStripe: finalResponse.receipt_url,
          metodo: "tpv",
          monto: recibo.iva ? (montoAPagar / 100) / 1.16 : montoAPagar / 100,
          persona: cliente.empresa,
          fechaPago: new firebase.firestore.Timestamp.fromDate(new Date())
        }).then(() => {
          reFetchData();
          setConekta(false);
          setAlerta({
            isOpen: true,
            type: "success",
            message: "Tu pago se ha recibido de manera exitosa, gracias :)"
          });
          setTimeout(()=> {
            setAlerta({
              isOpen: false,
              type: "danger",
              message: ""
            });
          }, 3000);
          sendMail(
            cliente.correo,
            "Recibo Acromático Development",
            `
              <h1>¡${recibo.persona} Gracias por tu Preferencia!</h1>
              <p>
                Tu pago con tarjeta ha sido procesado con exito,
                puedes consultar una copia de dicho recibo <a href="https://recurrentes.acromatico.co/recibo/${props.match.params.id}">aquí</a>.
              </p>
              <p>Revísa el cargo que hicimos a tu tarjeta <a href="${finalResponse.receipt_url}">aquí</a>.</p>
            `
          );
          sendSMS(
            cliente.telefono,
            `¡Acromático Development agradece tu preferencia! Consulta tu recibo en: https://recurrentes.acromatico.co/recibo/${props.match.params.id}`
          );
        }).catch(err => {
          throw new Error("Tu tarjeta se cobró más el pago no pudo guardarse en nuestra base de datos, comunícate al (55) 1295 2572.")
        });
      } else {
        throw new Error("Error desconocido, intente más tarde");
      }
      
    } catch(errores) {
      console.log(errores);
      setConekta(false);
      setAlerta({
        isOpen: true,
        type: "danger",
        message: errores.message
      });
    }
  }

  let renderData;

  if(found) {
    renderData = (
      <Row className="justify-content-md-center">
        <Col md={8} style={{
          display: 'flex',
          flexDirection: "column",
          justifyContent: "center",
          alignItems: 'center',
          minHeight: '100vh'
        }}>
          <img src="/assets/img/Logo.png" alt="Acromático Development" style={{
            width: "500px",
            maxWidth: "90%",
            margin: "0 0 20px"
          }}/>
          <Card>
            <Card.Body>
              <Card.Title>
                <h3>{cliente.empresa}</h3>
                <h5>Recibo {recibo.status ? null : <strong>pendiente de pago </strong>}{recurrente.frecuencia !== 'unico' && <span>para el periodo del <strong>{recibo.periodo.toDate().toLocaleDateString()}</strong> al <strong>{recibo.siguientePeriodo.toDate().toLocaleDateString()}</strong></span>}</h5>
                <h4>Proyecto: {recurrente.concepto}</h4>
                <h5>Concepto: {recibo.concepto ? recibo.concepto : "Sin Concepto"}</h5>
              </Card.Title>
              <Card.Text>
                Fecha de Recibo: {recibo.creacion.toDate().toLocaleString()}<br/>
                Frecuencia: {recurrente.frecuencia}<br/>
                {recurrente.frecuencia !== 'unico' && <span>Periodo de Recibo: {recibo.periodo.toDate().toLocaleDateString()} al {recibo.siguientePeriodo.toDate().toLocaleDateString()}<br/></span>}
                {recibo.status ? (
                  <span>
                    Monto pagado: {recibo.iva ? formatter.format(recibo.monto * 1.16) + " IVA incluido" : formatter.format(recibo.monto) +  " " + (recibo.moneda ? recibo.moneda : 'MXN')}<br/>
                    Pagado por: {recibo.persona}<br/>
                    Método de Pago: {recibo.metodo}<br/>
                    Fecha de Pago: {recibo.fechaPago ? recibo.fechaPago.toDate().toLocaleString() : "Hubo un Erro!"}
                  </span>
                ) : (
                  <span style={{
                    color: 'red'
                  }}>
                    Monto a Pagar: {formatter.format(recibo.monto) +  " " + (recibo.moneda ? recibo.moneda : 'MXN')}
                    {recibo.iva && (
                      <>
                        <br/>
                        IVA: {formatter.format(recibo.monto * 0.16) +  " " + (recibo.moneda ? recibo.moneda : 'MXN')}
                        <br/>
                        Total: {formatter.format(recibo.monto * 1.16) +  " " + (recibo.moneda ? recibo.moneda : 'MXN')}
                      </>
                    )}
                  </span>
                )}
                
              </Card.Text>
              {!recibo.status ? (
                <div>
                  <Card.Title>
                    <h3>Proceder al Pago</h3>
                  </Card.Title>
                  <Card.Text>
                    <Button variant="dark" onClick={handlePagar}>Pagar</Button>
                  </Card.Text>
                </div>
              ) : null}
            </Card.Body>
            <Card.Footer>
              <Button variant="link">
                <Link to="/terminos-condiciones">Términos y Condiciones</Link>
              </Button>
              <Button variant="link">
                <Link to="/politicas-devoluciones">Devoluciones</Link>
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    );
  } else {
    renderData = (
      <Row>
        <Col>
          <h3>No se encontró el recibo</h3>
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <Container>
        {!loading ? (
          <Row>
            <Col>
              <h3>Cargando...</h3>
            </Col>
          </Row>
        ) : renderData}
        <Modal size="lg" show={paymentIsOpen} onHide={closePayment}>
          <Modal.Header closeButton>
            <Modal.Title>Pago de Recibo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>Pago con Tarjeta</h3>
            <p><small>Se cobra un 3.5% {recibo.iva && "más IVA"} extra de cómisión al pagar con tarjeta.</small></p>
            <p><small>Te haremos llegar una copia de tu recibo a {cliente.correo} y por mensaje al {cliente.telefono}</small></p>
            <Form>
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="nombre">
                  <Form.Label>Nombre del tarjetahabien</Form.Label>
                  <Form.Control type="text" placeholder="Rafael González V." value={recibo.persona} onChange={(e) => handleForm("persona", e.target.value)} required/>
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="numeroTarjeta">
                  <Form.Label>Tarjeta</Form.Label>
                  <CardElement />
                </Form.Group>
              </Form.Row>
            </Form>
            
            <h5 className="my-3">Monto a pagar: {recibo.iva ? formatter.format(((recibo.monto * 1.035)) * 1.16) + " " + (recibo.moneda ? recibo.moneda : 'MXN') : formatter.format((recibo.monto * 1.035)) + " " + (recibo.moneda ? recibo.moneda : 'MXN')}</h5>
            {waitingConekta ? (
              <p className="my-3">El pago se está procesando...</p>
            ) : (
              <div>
                {!recibo.status ? <Button variant="dark" onClick={stipeSubmit}>Pagar</Button> : <h3 style={{color: "green"}}>Pagado :)</h3>}
              </div>
            )}
            {/* <Form onSubmit={handlePago}>
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="nombre">
                  <Form.Label>Nombre del tarjetahabien</Form.Label>
                  <Form.Control type="text" placeholder="Rafael González V." value={cardData.name} onChange={(e) => handleForm("name", e.target.value)} required/>
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="tarjeta">
                  <Form.Label>Número de Tarjeta</Form.Label>
                  <Cleave
                    placeholder="Agrega tu número de tarjeta"
                    options={{creditCard: true}}
                    value={cardData.number}
                    onChange={(e) => handleForm("number", e.target.rawValue)} 
                    className="form-control" required />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={4} controlId="cvc">
                  <Form.Label>CVC</Form.Label>
                  <Form.Control type="text" placeholder="123" maxLength={4} value={cardData.cvc} onChange={(e) => handleForm("cvc", e.target.value)} required/>
                </Form.Group>
                <Form.Group as={Col} sm={4} controlId="mes">
                  <Form.Label>Mes de Vencimiento</Form.Label>
                  <Form.Control as="select" value={cardData.exp_month} onChange={(e) => handleForm("exp_month", e.target.value)}>
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} sm={4} controlId="tarjeta">
                  <Form.Label>Año de Vencimiento</Form.Label>
                  <Form.Control type="text" placeholder="2021" maxLength={4} value={cardData.exp_year} onChange={(e) => handleForm("exp_year", e.target.value)} required/>
                </Form.Group>
              </Form.Row>
              <h5>Monto a pagar: {formatter.format(recibo.monto)}</h5>
              {waitingConekta ? (
                <p>El pago se está procesando...</p>
              ) : (
                <div>
                  {!recibo.status ? <Button variant="dark" type="submit">Pagar</Button> : <h3 style={{color: "green"}}>Pagado :)</h3>}
                </div>
              )}
            </Form> */}
            {alerta.isOpen ? <Alert variant={alerta.type} className="my-3">{alerta.message}</Alert> : null}
          </Modal.Body>
          <Modal.Body>
            <h3>Transferencia / Depósito en Efectivo</h3> 
            <p>
              Nombre: Lycklig SAS de CV<br/>
              Banco: BBVA Bancomer<br/>
              Cuenta: 0110748455<br/>
              CLABE: 0121 8000 1107 4845 59
            </p>
            <p>Favor de notificar el depósito / Transferencia a <a href="mailto:hola@acromatico.co">hola@acromatico.co</a></p>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  )
};

export default withRouter(injectStripe(ReciboClientView));
