import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

import { logout } from '../../helpers/firebase';



const Layout = (props) => {
    return(
        <div>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand href="#">
                <img
                    src="/assets/img/square-logo.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="Acromatico Logo"
                />
                </Navbar.Brand>
                <Nav>
                    <Nav.Item>
                        <Nav.Link as="div"><Link to="/" style={{color: "#dfdfdf"}}>Operaciones</Link></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as="div"><Link to="/clientes" style={{color: "#dfdfdf"}}>Clientes</Link></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as="div"><Link to="/estadisticas" style={{color: "#dfdfdf"}}>Estadisticas</Link></Nav.Link>
                    </Nav.Item>
                </Nav>
                <Nav className="ml-auto">
                    <Nav.Item>
                        <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar>

            {props.children}

        </div>
    )
}

export default Layout;