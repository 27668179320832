import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import './ClientesList.scss';

const ClientesList = (props) => {
  const clientList = props.Lista;

  return (
    <div>
      <Table responsive className="Tabla__Container" style={{
        maxHeight: "50px",
      }}>
        <thead>
          <tr>
            <th>Empresa</th>
            <th>Contacto</th>
            <th>Correo</th>
          </tr>
        </thead>
        <tbody>
          {clientList.map((elemento) => {

            return (
                <tr key={elemento.id}>
                    <td>{elemento.data().empresa}<br/><Link to={`/cliente/${elemento.id}`}><Button variant="dark" className="my-3">Editar</Button></Link></td>
                    <td>{elemento.data().contacto}</td>
                    <td>{elemento.data().correo}</td>
                </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
};

export default ClientesList;
