import React from 'react';
import { Link } from 'react-router-dom';
import {Nav, Navbar, Container, Row, Col, Button} from 'react-bootstrap';

const Devoluciones = () => {
  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="#">
          <img
            src="./assets/img/square-logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Acromatico Logo"
          />
        </Navbar.Brand>
        <Nav className="ml-auto">
          <Link to="/login">
            <Button variant="light">
              Inicio
            </Button>
          </Link>
        </Nav>
      </Navbar>
      <Container>
        <Row>
          <Col className="my-3">
            <h1>DEVOLUCIONES Y CANCELACIONES</h1>

            <p>Para poder cancelar un cargo recurrente, ser&aacute; necesario dar notificaci&oacute;n via correo electr&oacute;nico a la direcci&oacute;n hola@acromatico.co, antes de haber transcurrido los primeros 5 d&iacute;as del periodo de servicio vigente; es decir, si se quiere cancelar un servicio recurrente y el siguiente periodo de dicho servicio abarca del 1 de mayo del 2019 al 31 de mayo del 2019, se contar&aacute; hasta el 5 de mayo de 2019 para notificar la cancelaci&oacute;n.</p>

            <p>Para ser elegible para una devoluci&oacute;n se deber&aacute; haber hecho el pago del periodo de servicio antes de haber dado inicio con &eacute;l mismo y se deber&aacute; notificar la cancelaci&oacute;n via correo electr&oacute;nico a la direcci&oacute;n hola@acromatico.co, antes de haber transcurrido los primeros 5 d&iacute;as de servicio del periodo. Ser&aacute; necesario presentar los siguientes documentos para solicitar la devoluci&oacute;n:</p>

            <ul>
              <li>Copia del recibo de pago.</li>
              <li>Copia de la notificaci&oacute;n de cancelaci&oacute;n de servicio.</li>
              <li>&Uacute;ltimos cuatro d&iacute;gitos de la tarjeta que se utiliz&oacute; para generar el cargo.</li>
            </ul>

            <h2>Reembolsos (si aplica)</h2>

            <p>Una vez recibida la informaci&oacute;n necesaria para la devoluci&oacute;n, le notificaremos de la aprobaci&oacute;n o rechazo de su reembolso. Si se aprueba, el reembolso se procesa y un cr&eacute;dito se aplicar&aacute; autom&aacute;ticamente a su tarjeta de cr&eacute;dito o al m&eacute;todo de pago original dentro de una cantidad determinada de d&iacute;as.</p>

            <h2>Reembolsos tard&iacute;os o faltantes (si aplica)</h2>

            <p>Si a&uacute;n no ha recibido un reembolso, compruebe primero su cuenta bancaria de nuevo. A continuaci&oacute;n, p&oacute;ngase en contacto con su compa&ntilde;&iacute;a de tarjeta de cr&eacute;dito, puede tomar alg&uacute;n tiempo antes de que su reembolso se refleje oficialmente. P&oacute;ngase en contacto con su banco. A menudo hay un tiempo de procesamiento antes de que se publique un reembolso. Si has hecho todo esto y a&uacute;n no has recibido tu reembolso, ponte en contacto con nosotros a&#160;hola@acromatico.co.</p>

            <h2>Servicios en rebaja (si aplica)</h2>

            <p>Solamente los servicios regulares pueden ser reembolsados.</p>
            <p>Preguntas acerca de nuestras póliticas de devolución y cancelación deben ser enviadas a <a href="mailto:hola@acromatico.co">hola@acromatico.co.</a></p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Devoluciones;