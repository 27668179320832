import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';
import { login } from '../../helpers/firebase';

const Login = (props) => {
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });
  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    bsColor: "danger"
  });

  useEffect(() => {
    if(props.User && props.User !== 'iniciando'){
      props.history.push('/');
    } else {
      window.ga('send', 'pageview');
    }
  },[
    props.User
  ]);

  const handleForm = (nombre, valor) => {
    let newState = {
      ...formData
    };

    newState[nombre] = valor;
    
    setFormData(newState);
  };

  const handleLogin = (eve) => {
    eve.preventDefault();

    if(formData.username !== "" && formData.password !== ""){
      login(formData.username, formData.password)
        .catch(err => {
          setAlert({
            isOpen: true,
            message: err.message,
            bsColor: "danger"
          })
        });
    } else {
      setAlert({
        isOpen: true,
        message: "Por favor llena todos los datos",
        bsColor: "danger"
      });
    }

    
  };

  return (
    <div>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={6} style={{
            display: 'flex',
            alignItems: 'center',
            height: '100vh'
          }}>
          <Card>
            <Card.Body>
              <Card.Img variant="top" src="./assets/img/Logo.png" />
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="usuario">
                  <Form.Label>Correo</Form.Label>
                  <Form.Control type="email" placeholder="hola@acromatico.co" value={formData.username} onChange={(e) => handleForm("username", e.target.value)} required/>
                </Form.Group>
                <Form.Group controlId="contrasena">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control type="password" value={formData.password} onChange={(e) => handleForm("password", e.target.value)} required/>
                </Form.Group>
                <Button variant="dark" type="submit">Login</Button>
              </Form>
              {alert.isOpen ? (
                <Alert variant={alert.bsColor} className="my-3">
                  {alert.message}
                </Alert>
              ) : null}
            </Card.Body>
          </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default withRouter(Login);
