import React, {useEffect, useState} from 'react';
import { Route } from 'react-router-dom';
import firebase from './helpers/firebase';

import App from './App';
import Recurrent from './containers/RecurrentChargeBetter/RecurrentChargeBetter';
import Clientes from './containers/Clientes/Clientes';
import Cliente from './containers/Cliente/Cliente';
import Stats from './containers/Stats/Stats';
import Login from './containers/Login/Login';
import ReciboClientView from './containers/ReciboClientView/ReciboClientView';
import Terminos from './containers/Terminos/Terminos';
import Devoluciones from './containers/Devoluciones/Devoluciones';

const Routes = (props) => {
  const [user, setUser] = useState('iniciando');

  useEffect(() => {
    const observable = firebase.auth().onAuthStateChanged((usuario) => {
      if (usuario) {
        setUser(usuario);
      } else {
        setUser(null);
      }
    });

    return function cleanup() {
      observable();
    };
  }, [
    user
  ]);

  return (
    <div>
      <Route path="/recibo/:id" render={() => <ReciboClientView />}/>
      <Route path="/terminos-condiciones" render={() => <Terminos />} />
      <Route path="/politicas-devoluciones" render={() => <Devoluciones />} />
      <Route path="/login" render={() => <Login User={user} />} />
      <Route path="/cliente/:id" render={() => <Cliente User={user} />}/>
      <Route path="/clientes" render={() => <Clientes User={user} />} />
      <Route path="/operacion/:id" render={() => <Recurrent User={user} />} />
      <Route path="/estadisticas" render={() => <Stats User={user} />} />
      <Route path="/" exact render={() => <App User={user} />}/>
    </div>
  )
};

export default Routes;