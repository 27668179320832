import React, {useEffect, useState} from 'react';
import { ResponsiveLine } from '@nivo/line';
import { getRecibosPagados } from '../../helpers/firebase';

import Layout from '../Layout/Layout';

const Stats = (props) => {
    const [year, setYear] = useState(2019);
    const [values, setValues] = useState([
        {
            x: "Enero",
            y: 0
        },
        {
            x: "Febrero",
            y: 0
        },
        {
            x: "Marzo",
            y: 0
        },
        {
            x: "Abril",
            y: 0
        },
        {
            x: "Mayo",
            y: 0
        },
        {
            x: "Junio",
            y: 0
        },
        {
            x: "Julio",
            y: 0
        },
        {
            x: "Agosto",
            y: 0
        },
        {
            x: "Septiembre",
            y: 0
        },
        {
            x: "Octubre",
            y: 0
        },
        {
            x: "Noviembre",
            y: 0
        },
        {
            x: "Diciembre",
            y: 0
        }
    ]);

    const [valuesCostos, setValuesCostos] = useState([
        {
            x: "Enero",
            y: 0
        },
        {
            x: "Febrero",
            y: 0
        },
        {
            x: "Marzo",
            y: 0
        },
        {
            x: "Abril",
            y: 0
        },
        {
            x: "Mayo",
            y: 0
        },
        {
            x: "Junio",
            y: 0
        },
        {
            x: "Julio",
            y: 0
        },
        {
            x: "Agosto",
            y: 0
        },
        {
            x: "Septiembre",
            y: 0
        },
        {
            x: "Octubre",
            y: 0
        },
        {
            x: "Noviembre",
            y: 0
        },
        {
            x: "Diciembre",
            y: 0
        }
    ]);

    useEffect(() => {
        updateDatos();
    }, []);

    const updateDatos = () => {
        getRecibosPagados().get()
            .then(result => {
                let newValues = [ ...values ];
                let newValuesCostos = [...valuesCostos];
                    
                result.forEach(doc => {
                    const date = new Date(doc.data().fechaPago.toDate());
                    if(date.getFullYear() === year){
                        newValues[date.getMonth()].y += parseFloat(doc.data().monto);
                        newValuesCostos[date.getMonth()].y += 1;
                    }
                });

                setValues(newValues);
                setValuesCostos(newValuesCostos);
            })
            .catch(err => console.log(err));
    }

    return (
        <Layout>
            <div style={{
                width: "100%",
                height: "80vh"
            }}>
                <ResponsiveLine
                    data={[
                        {
                            id: "Costos",
                            color: "hsl(170, 70%, 50%)",
                            data: valuesCostos
                        },
                        {
                            id: "Ingresos",
                            color: "hsl(127, 70%, 50%)",
                            data: values
                        }
                    ]}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', stacked: true, min: 'auto', max: 'auto' }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Mes',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Monto',
                        legendOffset: -50,
                        legendPosition: 'middle'
                    }}
                    colors={{ scheme: 'accent' }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}/>
            </div>
        </Layout>
    )
}

export default Stats;